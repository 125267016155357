















































































































import { Component } from 'vue-property-decorator'
import axios, { CancelTokenSource } from 'axios'
import { Bind, Debounce } from 'lodash-decorators'
import { isEqual } from 'lodash'

// components
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import CourseSearch from '@/components/_uikit/search/CourseSearch.vue'
import DateInput from '@/components/_uikit/controls/DateInput.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import DictionaryModule from '@/store/modules/dictionary'
import ManagerDiscountModule from '@/store/modules/manager/discounts'
import { IDiscountStatsFilter, SaleStatShortResource } from '@/store/types'
// utils
import { tableFooterOptions } from '@/utils/constants'
import { GET_DEFAULT_TABLE_FILTER } from '@/store'

@Component({
  components: {
    Autocomplete,
    ButtonTextIcon,
    CourseSearch,
    DateInput,
    Select,
    TableFooter,
    Tag,
    TextInput,
  },
})
export default class DiscountsStatsTable extends NotifyMixin {
  private get discounts() {
    return DictionaryModule.discounts
  }
  private get discountStats() {
    return ManagerDiscountModule.discountStats
  }
  private get filter() {
    return ManagerDiscountModule.discountsStatsFilter
  }

  private set filter(filter: IDiscountStatsFilter) {
    if (!isEqual(filter, this.filter) || !this.discountStats.data.length) {
      ManagerDiscountModule.setDiscountsStatsFilter(filter)
      this.fetchDiscountStats()
    }
  }

  private get footerOptions() {
    return tableFooterOptions
  }

  private get headers() {
    return [
      { cellClass: 'cell-width-30', sortable: false, text: 'Почта', value: 'email' },
      { cellClass: 'cell-width-30', sortable: false, text: 'Цель', value: 'target' },
      { cellClass: 'cell-width-12', sortable: false, text: 'Статус цели', value: 'status' },
      { cellClass: 'cell-width-12', sortable: false, text: 'Кол-во месяцев в цели', value: 'monthCount' },
    ]
  }

  private get orderStatuses() {
    return DictionaryModule.orderStatuses
  }

  private get orderStatusValue() {
    const obj: Record<string | number, string> = {}
    this.orderStatuses.forEach(item => obj[item.value] = item.name)
    return obj
  }

  private filterSourceDates: string[] = this.filter.sourcePaidPeriodStart && this.filter.sourcePaidPeriodEnd ? [this.filter.sourcePaidPeriodStart, this.filter.sourcePaidPeriodEnd] : []
  private filterTargetDates: string[] = this.filter.targetPaidPeriodStart && this.filter.targetPaidPeriodEnd ? [this.filter.targetPaidPeriodStart, this.filter.targetPaidPeriodEnd] : []

  private isLoading = false
  private isShowMoreFilters = false
  private source: CancelTokenSource | null = null

  private mounted() {
    if (!this.discounts.length) {
      DictionaryModule.fetchDiscounts()
        .catch(this.notifyError)
    }
  }

  @Bind()
  @Debounce(500)
  private fetchDiscountStats() {
    const CancelToken = axios.CancelToken
    if (this.isLoading && this.source) {
      this.source.cancel()
    }
    this.source = CancelToken.source()
    this.isLoading = true
    ManagerDiscountModule.fetchDiscountStats({ cancelToken: this.source.token, filter: this.filter })
      .then(() => {
        this.isLoading = false
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          this.notifyError(err)
          this.isLoading = false
        }
      })
  }

  private handleFilter(field: keyof IDiscountStatsFilter | 'targetDates' | 'sourceDates', value: any) {
    if (field === 'targetDates') {
      if (value.length) {
        this.filter = {
          ...this.filter,
          targetPaidPeriodEnd: value[1],
          targetPaidPeriodStart: value[0],
        }
      } else {
        this.filter.targetPaidPeriodStart = undefined
        this.filter.targetPaidPeriodEnd = undefined
      }
    } else if (field === 'sourceDates') {
      if (value.length) {
        this.filter = {
          ...this.filter,
          sourcePaidPeriodEnd: value[1],
          sourcePaidPeriodStart: value[0],
        }
      } else {
        this.filter.sourcePaidPeriodStart = undefined
        this.filter.sourcePaidPeriodEnd = undefined
      }
    } else {
      this.filter[field] = value as unknown as never
    }

    this.filter = {
      ...this.filter,
      page: 1,
    }
  }

  private resetFilter() {
    this.filterSourceDates = []
    this.filterTargetDates = []
    this.filter = {
      ...GET_DEFAULT_TABLE_FILTER(),
      itemsPerPage: 10,
      saleSource: undefined,
      saleTarget: undefined,
      sourceStatus: undefined,
      targetStatus: undefined,
      search: undefined,
      targetPaidPeriodStart: undefined,
      targetPaidPeriodEnd: undefined,
      sourcePaidPeriodStart: undefined,
      sourcePaidPeriodEnd: undefined,
      targetMonthsCount: undefined,
      sourceMonthsCount: undefined,
      saleId: undefined,
      page: 1,
    }
  }

  private handleRowClick(item: SaleStatShortResource) {
    this.$router.push({
      name: 'manager.order.item',
      params: {
        id: item.targetOrderId.toString(),
      },
    })
  }
}
