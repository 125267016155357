





import { Component ,Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

// components
import DiscountsStatsTable from '@/components/tables/DiscountsStatsTable.vue'

@Component({
  components: {
    DiscountsStatsTable,
  },
})
export default class Stats extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Статистика по скидкам',
    }
  }
}
